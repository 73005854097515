import React from 'react';
import { Grid, Button } from '@material-ui/core';

const Authorize = (props) => {
  const { authorizeSpotify } = props;

  return (
    <Grid container className='mt-100' justifyContent="center">
      <Button variant="contained" size="large" className="song-vote-button" onClick={authorizeSpotify}> Authorize</Button>
    </Grid>
  );
}

export default Authorize;