import React, { useEffect, useState } from 'react';
import AdminQueue from '../Components/AdminQueue';
import SpotifyService from '../Services/SpotifyService';

const AdminQueueContainer = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentlyPlayingSong, setCurrentlyPlayingSong] = useState(null);
  const [queue, setQueue] = useState([]);

  useEffect(() => {
    currentlyPlaying();
    setInterval(() => currentlyPlaying(), 10 * 1000);
    document.title = "Neon Nights - Reihenfolge"
  }, []);

  const currentlyPlaying = async () => {
    try {
      setIsLoading(true);
      const response = await SpotifyService.currentlyPlaying();
      setIsLoading(false);
      if (response.body?.is_playing) {
        setCurrentlyPlayingSong(response.body);
      } else {
        setCurrentlyPlayingSong(null)
      }
      setQueue(response.body.queue);
    } catch (e) {
      setIsLoading(false);
    }

  }


  return <AdminQueue currentlyPlaying={currentlyPlaying} isLoading={isLoading} currentlyPlayingSong={currentlyPlayingSong} queue={queue} />
}
export default AdminQueueContainer;