import React, { useEffect, useState } from 'react';
import AdminLimitSongForm from '../Components/AdminLimitSongForm';
import SpotifyService from "../Services/SpotifyService";
import { useSnackbar } from 'notistack';

const AdminLimitSongFormContainer = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [songLimit, setSongLimit] = useState(null);

  useEffect(() => {
    SpotifyService.getSongLimit().then((response) => {
      setSongLimit(response?.body?.song_limit);
    });
  }, [])

  const handleSongLimitChange = (e) => {
    setSongLimit(e.target.value);
  }

  const saveSongLimit = async () => {
    try {
      if (!songLimit) {
        enqueueSnackbar('Please enter song limit', { variant: 'error' });
        return;
      }
      setIsSubmitting(true);
      await SpotifyService.saveSongLimit({ song_limit: songLimit });
      setIsSubmitting(false);
      enqueueSnackbar('Song limit saved successfully', { variant: 'success' });
    } catch (e) {
      setIsSubmitting(false);
    }
  }
  return <AdminLimitSongForm songLimit={songLimit} handleSongLimitChange={handleSongLimitChange} saveSongLimit={saveSongLimit} isSubmitting={isSubmitting} />
}
export default AdminLimitSongFormContainer;