import React, { useState, useEffect } from 'react';
import { Grid, Button, Box } from '@material-ui/core';
import { Typography, TextField, Autocomplete, CircularProgress, InputAdornment, IconButton, OutlinedInput, FormControl } from '@mui/material';
import truncateString from "../Utils/truncateString";
import CloseIcon from '@mui/icons-material/Close';
import millisToMinutesAndSeconds from '../Utils/millisToMinutesAndSeconds';

const Home = (props) => {
  const { songsList, handleSearchValue, selectedSong, partyName, handlePartyName, handleSubmit, isSubmitting, autocompleteLoading, songSelector, mobileSongSelector, setLastElement, emptySongsList, outlinedInputRef, suggestionsList, songSuggesionsSelector, scrollToViewRef } = props;
  const [state, setState] = useState({ mobileView: false });
  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);




  const MobileViewSelectedSong = (props) => {
    const { selectedSong } = props;
    return <Grid container justifyContent='flex-end' className='pl-15'>
      <Grid item>
        <div className="mt-10" style={{ fontWeight: 600, margin: 0, lineHeight: 1 }}>
          {truncateString(selectedSong?.label, 16)}
        </div>
        <div style={{ marginTop: 10 }}>
          {selectedSong?.artist}
        </div>
        <div>
          {millisToMinutesAndSeconds(selectedSong?.duration)}
        </div>
      </Grid>
    </Grid>
  }

  const DesktopViewSelectedSong = (props) => {
    const { selectedSong } = props;
    return <Grid container className='pl-15'>
      <Grid item xs={11}>
        <div className="mt-10" style={{ fontWeight: 600, margin: 0, lineHeight: 1 }}>
          {selectedSong?.label}
        </div>
        <div style={{ marginTop: 15 }}>
          {selectedSong?.artist}
        </div>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="caption" className='pl-110' gutterBottom>
          {millisToMinutesAndSeconds(selectedSong?.duration)}
        </Typography>
      </Grid>
    </Grid>
  }

  return (
    <Grid container className='mt-100' justifyContent="center">
      <Grid item xs={12} >
        {mobileView ? <>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} style={{ paddingBottom: 10 }}>
                <Typography variant="h5" gutterBottom>
                  SONG VORSCHLAGEN
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: 10 }}>
                <FormControl variant="outlined" fullWidth ref={scrollToViewRef}>
                  <OutlinedInput
                    placeholder='Nach Song suchen'
                    onChange={handleSearchValue}
                    id="outlined-mobile-search"
                    type='text'
                    inputRef={outlinedInputRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle search visibility"
                          onClick={emptySongsList}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {songsList.map((s, i) => {
            return <div key={i}>{i === songsList.length - 1 ? <Grid item xs={12} className='mt-10' onClick={() => mobileSongSelector(s)} ref={setLastElement}>
              <Grid container>
                <Grid item xxl={1} xl={1} lg={1} md={1} sm={1}>
                  <Box
                    component="img"
                    sx={{
                      height: 83,
                      width: 95,
                    }}
                    alt={s?.label}
                    src={s?.image}
                  />
                </Grid>
                <Grid item xxl={10} xl={10} lg={10} md={10} sm={10}>
                  <Grid container justifyContent='flex-end' className='mt-10 pl-15'>
                    <Grid item>
                      <div style={{ fontWeight: 600, margin: 0, lineHeight: 1 }}>
                        {truncateString(s?.label, 26)}
                      </div>
                      <div style={{ marginTop: 15 }}>
                        {s?.artist}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> : <Grid item xs={12} className='mt-10' key={i} onClick={() => mobileSongSelector(s)}>
              <Grid container>
                <Grid item xxl={1} xl={1} lg={1} md={1} sm={1}>
                  <Box
                    component="img"
                    sx={{
                      height: 83,
                      width: 95,
                    }}
                    alt={s?.label}
                    src={s?.image}
                  />
                </Grid>
                <Grid item xxl={10} xl={10} lg={10} md={10} sm={10}>
                  <Grid container justifyContent='flex-end' className='mt-10 pl-15'>
                    <Grid item>
                      <div style={{ fontWeight: 600, margin: 0, lineHeight: 1 }}>
                        {truncateString(s?.label, 26)}
                      </div>
                      <div style={{ marginTop: 15 }}>
                        {s?.artist}
                      </div>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}</div>
          })}
        </> : <Autocomplete
          ref={scrollToViewRef}
          value={selectedSong}
          loading={autocompleteLoading}
          onChange={songSelector}
          onInputChange={handleSearchValue}
          options={[...songsList]}
          renderOption={(props, option, { selected }) => {
            return <div key={option.index}>{
              option.index === songsList.length - 1 ? <li {...props} key={option.index} style={{ marginTop: 20 }} ref={setLastElement}>
                <Grid container alignItems="center">
                  <Grid item xxl={1} xl={1} lg={1} md={1} sm={5}>
                    <Box
                      component="img"
                      sx={{
                        height: 53,
                        width: 65,
                        marginLeft: 10
                      }}
                      alt={option.label}
                      src={option.image}
                    />
                  </Grid>
                  <Grid item xxl={9} xl={9} lg={9} md={9} sm={5} style={{ paddingLeft: 20 }}>
                    <Grid container>
                      <Grid item>
                        <div style={{ fontWeight: 600, margin: 0, lineHeight: 1 }}>{option.label}</div>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item>
                        <div style={{ marginBottom: 15 }}>{option.artist}</div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </li> : <li {...props} key={option.index} style={{ marginTop: 20 }}>
                <Grid container alignItems="center">
                  <Grid item xxl={1} xl={1} lg={1} md={1} sm={5}>
                    <Box
                      component="img"
                      sx={{
                        height: 53,
                        width: 65,
                        marginLeft: 10
                      }}
                      alt={option.label}
                      src={option.image}
                    />
                  </Grid>
                  <Grid item xxl={9} xl={9} lg={9} md={9} sm={5} style={{ paddingLeft: 20 }}>
                    <Grid container>
                      <Grid item>
                        <div style={{ fontWeight: 600, margin: 0, lineHeight: 1 }}>{option.label}</div>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item>
                        <div style={{ marginTop: 15 }}>{option.artist}</div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </li>
            }</div>

          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterOptions={(options) => options}
          renderInput={(params) => <TextField error={!selectedSong && isSubmitting} {...params} label="Nach Song suchen" size="small" />}
        />}
      </Grid>
      {selectedSong && <>
        <Grid item xs={12} className='mt-20'>
          <Grid item xs={9} >
            <Typography variant="h6" gutterBottom>
              Selected
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='mt-10 results-container'>
          <Grid container>
            <Grid item xxl={1} xl={1} lg={1} md={1} sm={1}>
              <Box
                component="img"
                sx={{
                  height: 83,
                  width: 95,
                }}
                alt={selectedSong?.label}
                src={selectedSong?.image}
              />
            </Grid>
            <Grid item xxl={10} xl={10} lg={10} md={10} sm={10}>
              {mobileView ? <MobileViewSelectedSong selectedSong={selectedSong} /> : <DesktopViewSelectedSong selectedSong={selectedSong} />}
            </Grid>
          </Grid>
        </Grid>
      </>}
      <Grid item xs={12} className='mt-20'>
        <Grid container justifyContent="space-between">
          <Grid item xxl={7} xl={7} lg={7} md={7} sm={7} xs={12} style={{ paddingBottom: 10 }}>
            <TextField error={!partyName && isSubmitting} label={"Euer Bühnen-Name"} fullWidth size="small" onChange={handlePartyName} />
          </Grid>
          <Grid item xxl={4} xl={4} lg={4} md={4} sm={4} xs={12} >
            <Button variant="contained" size="large" onClick={handleSubmit} fullWidth className='song-vote-button'>
              {isSubmitting && <CircularProgress size={14} color="inherit" />} &nbsp; <span className={`${mobileView ? "font-size-12" : "font-size-14"}`}>Song vorschlagen</span>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className='mt-30'></Grid>

      <Grid item xs={12} className='mt-20'>
        <Grid container>
          <Grid item xs={12} >
            <Typography variant="h5" gutterBottom>
              SONG-IDEEN
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {suggestionsList.map((s, i) => {
        return <Grid key={i} item xs={12} className="mt-10 pointer" onClick={() => songSuggesionsSelector(s)}>
          <Grid container className={`${selectedSong?.id === s.id ? 'suggested-selected-song-container' : ''}`}>
            <Grid>
              <Box
                component="img"
                sx={{
                  height: 83,
                  width: 95,
                }}
                alt={s?.label}
                src={s?.image}
              />
            </Grid>
            <Grid item >
              <Grid container className='pl-15 mt-10'>
                <Grid item>
                  <div style={{ fontWeight: 600, margin: 0, lineHeight: 1 }}>
                    {mobileView ? truncateString(s?.label, 22) : s?.label}
                  </div>

                  <div style={{ marginTop: 15 }}>
                    {s?.artist}
                  </div>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      })}
      <Grid item xs={12} style={{ paddingBottom: 100 }}></Grid>
    </Grid>
  );
}

export default Home;