import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import WishQueue from "../Components/WishQueue";
import SpotifyService from '../Services/SpotifyService';

const WishQueueContainer = (props) => {
  const cookies = new Cookies();
  const [wishList, setWishList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setWishListWithStatus();
    const interval = setInterval(() => setWishListWithStatus(), 10 * 1000);
    document.title = "Neon Nights - Meine Wunschliste";
    return () => {
      clearInterval(interval);
    }
  }, []);

  const setWishListWithStatus = async () => {
    try {
      const response = await SpotifyService.currentlyPlaying();
      const queue = response.body.queue;
      const queueSongIds = queue.map((q) => q.id);
      if (response.body?.is_playing) {
        queueSongIds.push(response.body.id);
      }
      const cookiesWishLisFromCookies = cookies.get('cookiesWishList');

      if (cookiesWishLisFromCookies) {
        const cookiesWishList = cookiesWishLisFromCookies.map((q) => {

          if (q["isApproved"] && !q["wasPlayed"] && !queueSongIds.includes(q.id)) {
            q["isRejected"] = true;
            return q;
          }

          if (!q["isApproved"] && queueSongIds.includes(q.id)) {
            q["isApproved"] = true;
          }
          return q;
        });
        setWishList(cookiesWishList);
        const sortedArray = cookiesWishList.sort((a, b) => (b.added_at > a.added_at) ? 1 : ((a.added_at > b.added_at) ? -1 : 0))
        cookies.set('cookiesWishList', sortedArray);
      }
    } catch (e) {
      setWishList([]);
    }
  }

  const removeSongFromCookiesList = (selectedSong) => {
    const cookiesWishLisFromCookies = cookies.get('cookiesWishList');
    const filteredSongsList = cookiesWishLisFromCookies.filter((q) => q.id !== selectedSong.id);
    setWishList(filteredSongsList);
    cookies.set('cookiesWishList', filteredSongsList);
    setIsSubmitting(false);
  }

  const deleteSongFromQueue = async (selectedSong) => {
    try {
      setIsSubmitting(true);
      removeSongFromCookiesList(selectedSong);
      await SpotifyService.removeSongFromQueue(selectedSong.id);
    } catch (e) {
      setIsSubmitting(false);
    }
  }

  return <WishQueue wishList={wishList} deleteSongFromQueue={deleteSongFromQueue} isSubmitting={isSubmitting} />
}
export default WishQueueContainer;