import React, { useEffect, useState } from 'react';
import Authorize from '../Components/Authorize';

const AuthorizeContainer = (props) => {

  const [apiCredentials, setApiCredentials] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect_url = urlParams.get('redirect_url');
    const client_id = urlParams.get('client_id');
    setApiCredentials({ redirect_url, client_id });
  }, [])

  const authorizeSpotify = () => {

    const scope = 'playlist-modify-public playlist-modify-private user-read-playback-state user-library-read user-read-currently-playing';

    const spotifyAuthorizeUrl = ('https://accounts.spotify.com/authorize?' +
      objectToQueryString({
        response_type: 'code',
        client_id: apiCredentials.client_id,
        scope: scope,
        redirect_uri: apiCredentials.redirect_url
      }));



    window.location.replace(spotifyAuthorizeUrl);
  };

  const objectToQueryString = (obj) => {
    const keys = Object.keys(obj);
    const keyValuePairs = keys.map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    });
    return keyValuePairs.join('&');
  }

  return <Authorize authorizeSpotify={authorizeSpotify} />
}
export default AuthorizeContainer;