import {
  Typography,
  Grid,
} from "@material-ui/core";
import React from "react";
import InstagramIcon from '@mui/icons-material/Instagram';

export default function Footer() {


  const openInstagram = () => {
    window.location.href = "https://www.instagram.com/gesangskollektiv/"
  }

  return (
    <Grid item xs={12} style={{ position: "fixed", bottom: 0, width: "100%", backgroundColor: "rgba(0, 0, 0, 1.1)" }}>
      <Grid container justifyContent="center">
        <Grid item>
          <Typography style={{ marginTop: 15, marginBottom: 10 }}>
            <InstagramIcon className="pointer" onClick={openInstagram} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}