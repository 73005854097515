import React, { useState, useEffect } from 'react';
import { Grid, Box, Card, CardContent } from '@material-ui/core';
import { Typography } from '@mui/material';
import CurrentlyPlaying from './CurrentlyPlaying';

const Queue = (props) => {
  const { currentlyPlaying, isLoading, currentlyPlayingSong, queue } = props;
  const [state, setState] = useState({ mobileView: false });
  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const card = (
    <React.Fragment>

    </React.Fragment>
  );

  return (
    <Grid container className='mt-100' justifyContent="center">
      <CurrentlyPlaying currentlyPlaying={currentlyPlaying} isLoading={isLoading} currentlyPlayingSong={currentlyPlayingSong} mobileView={mobileView} />
      <Grid item xs={12} className='mt-50'>
        <Grid container justifyContent="space-between">
          <Grid item xs={7} >
            <Typography variant="h4" gutterBottom>
              {currentlyPlayingSong ? "Up Next" : ""}
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      {queue[0] && <Grid container justifyContent="center" className='mt-20'>
        <Grid item xs={12} >
          <Box>
            <Card variant="outlined" className='queue-card'><CardContent>
              <Typography sx={{ fontSize: 30, textAlign: "center", marginTop: 3 }} color="text.primary" gutterBottom>
                {queue[0]?.party_name}
              </Typography>
            </CardContent></Card>
          </Box>
        </Grid>
      </Grid>}
      <Grid item xs={12} style={{ paddingBottom: 100 }}></Grid>
    </Grid>
  );
}

export default Queue;