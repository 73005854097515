import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import { Typography, Divider, Chip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DesktopViewSelectedSong from '../Common/DesktopViewSelectedSong';
import MobileViewSelectedSong from '../Common/MobileViewSelectedSong';
import truncateString from "../Utils/truncateString";
import { CircularProgress } from '@mui/material';

const WishQueue = (props) => {
  const { wishList, deleteSongFromQueue, isSubmitting } = props;
  const [state, setState] = useState({ mobileView: false });
  const [selectedSong, setSelectedSong] = useState(null);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = React.useState(false);

  const handleOpenConfirmDeleteModal = (song) => {
    setSelectedSong(song);
    setOpenConfirmDeleteModal(true);
  };

  const handleCloseConfirmDeleteModal = () => {
    setSelectedSong(false);
    setOpenConfirmDeleteModal(false);
  };

  const confirm = () => {
    deleteSongFromQueue(selectedSong);
    setOpenConfirmDeleteModal(false);
  }

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <>
      <Dialog
        open={openConfirmDeleteModal}
        onClose={handleCloseConfirmDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Delete ${selectedSong?.label}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDeleteModal}>No</Button>
          <Button onClick={confirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container className='mt-100' justifyContent="center">
        {wishList.map((q, i) => {
          const isPending = !q.isRejected && !q.isApproved
          return <Grid item xs={12} className='mt-20 results-container' key={i}>
            <Grid container className='p-8'>
              <Grid item xs={12} md={10}>
                <Typography variant="h4" gutterBottom className="mt-20">
                  {mobileView ? truncateString(q?.party_name, 18) : q?.party_name}
                </Typography>
              </Grid>
              <Grid item xs={10} md={2}>

                {q.isRejected ? <Chip label="Heute leider nicht" sx={{ bgcolor: 'red', color: 'white' }} className='mt-20' /> : <Chip label={q.isApproved ? "Spielen wir!" : <>{isSubmitting ? <CircularProgress size={14} color="warning" /> : <>Auf der Warteliste</>}</>} color={q.isApproved ? "success" : "primary"} className='mt-20' />}
                {isPending && !isSubmitting && <IconButton aria-label="delete" size="large" className='mt-20' onClick={() => handleOpenConfirmDeleteModal(q)}>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>}
              </Grid>

            </Grid>
            <Divider className="divider-border-color" />

            <Grid container className='mt-10 p-8'>
              <Grid item xxl={1} xl={1} lg={1} md={1} sm={1}>
                <Box
                  component="img"
                  sx={{
                    height: 83,
                    width: 95,
                  }}
                  alt={q?.label}
                  src={q?.image}
                />
              </Grid>
              <Grid item xxl={10} xl={10} lg={10} md={10} sm={10}>
                {mobileView ? <MobileViewSelectedSong selectedSong={q} /> : <DesktopViewSelectedSong selectedSong={q} />}
              </Grid>
            </Grid>
          </Grid>
        })}
        <Grid item xs={12} style={{ paddingBottom: 100 }}></Grid>
      </Grid>
    </>
  );
}

export default WishQueue;