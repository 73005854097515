import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Grid,
  Box,
  Container
} from "@material-ui/core";
import { Menu } from '@mui/icons-material';
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { LogoImage } from "../images";

const headersData = [
  {
    label: "Song wählen",
    href: "/",
  },
  {
    label: "Reihenfolge",
    href: "/queue",
  },
  {
    label: "Meine Wunschliste",
    href: "/wish-queue",
  }
];

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "rgba(0, 0, 0, 0.87)",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFFFF",
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
  },
  toolbar: {
    display: "flex"
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

export default function Header(props) {
  const location = useLocation()
  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();
  const [pathName, setPathName] = useState("");

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className="toolbar">
        <Container maxWidth="lg">
          <Box>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Grid container>
                  <Grid item>
                    {Logo}
                  </Grid>
                  <Grid>
                    {getMenuButtons()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box></Container>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>


        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <div>{Logo}</div>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <IconButton
              {...{
                edge: "end",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <Menu />
            </IconButton>
          </Grid>
        </Grid>

      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  const Logo = (
    <Box
      component="img"
      sx={{
        width: 230,
        paddingTop: 10
      }}
      alt='Logo'
      src={LogoImage}
    />
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          className={`nav-button ${href === pathName ? 'nav-button-focus-border' : ''}`}
          {...{
            key: label,
            to: href,
            component: RouterLink,
          }}
        >
          {label}
        </Button>
      );
    });
  };

  return (
    <AppBar className={header}>
      {mobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  );
}