import React, { useEffect } from 'react';
import SpotifyService from '../Services/SpotifyService';
import { useSnackbar } from 'notistack';

const CallbackContainer = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    SpotifyService.getAccessToken({ code: urlParams.get("code") }).then(() => {
      enqueueSnackbar('Authorize successfully.', { variant: 'success' });
    });
  }, [])



  return <p>Callback</p>
}
export default CallbackContainer;