import axios from 'axios';
import SnackbarUtils, { SnackbarUtilsConfigurator } from './SnackbarUtils';

axios.interceptors.request.use((config) => {
  config.headers['Access-Control-Allow-Origin'] = '*';
  return config;
});

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    
    const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
    
    if (!expectedError) {
      SnackbarUtils.error('Something went wrong');
    } else {
      SnackbarUtils.error(error.response.data.error);
      
    }
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
