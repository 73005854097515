import React from 'react';
import { Grid, Box, InputBase, FormControl, Button } from '@material-ui/core';
import { alpha, styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
const AdminLimitSongForm = (props) => {
  const { songLimit, handleSongLimitChange, saveSongLimit, isSubmitting } = props;

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
      color: "#FFFFFF"
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#F3F6F9',
      border: '1px solid',
      borderColor: '#E0E3E7',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      color: "#FFFFFF",
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),

      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
        borderColor: '#2D3843',
      }),
    },
  }));
  return (
    <Grid container className='mt-100' justifyContent="center">
      <Grid item container >
        <Grid xs={6} >
          <Box
            component="form"
            noValidate
            sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, gap: 2 }}
          >
            <FormControl variant="standard">
              <BootstrapInput defaultValue={songLimit} placeholder='Enter song limit' onChange={handleSongLimitChange} />
            </FormControl>
          </Box>
        </Grid>
        <Grid sx={6}>
          <Button variant="contained" onClick={()=>saveSongLimit()} >
            Submit {isSubmitting && <CircularProgress size={14} color="inherit" />}
          </Button>
        </Grid>
      </Grid>
    </Grid>);
}

export default AdminLimitSongForm;