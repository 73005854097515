import React, { useState, useEffect, useCallback, useRef } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import debounce from "lodash/debounce";
import Home from '../Components/Home';
import SpotifyService from '../Services/SpotifyService';
import { Dialog, DialogTitle, DialogContent, Button, DialogContentText, DialogActions } from "@mui/material";


const HomeContainer = (props) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const outlinedInputRef = useRef();
  const scrollToViewRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar();
  const [songsList, setSongsList] = useState([]);
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [selectedSong, setSelectedSong] = useState(null);
  const [partyName, setPartyName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dialogueIsOpen, setDialogueIsOpen] = useState(false);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [lastElement, setLastElement] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [songLimit, setSongLimit] = useState(null);

  useEffect(() => {
    document.title = "Neon Nights - Song Wählen"
    SpotifyService.getSongSuggestions().then((response, error) => {
      const suggestions = response.suggestions ? response.suggestions : []
      const list = suggestions.map((s, i) => {
        const track = s.track;
        return { index: i, id: track.id, label: track.name, image: track.album?.images[0]?.url, duration: track.duration_ms, artist: track.artists[0].name, spotify_url: track.external_urls?.spotify }
      });

      setSuggestionsList(list);
    });
    SpotifyService.getSongLimit().then((response) => {
      setSongLimit(response?.body?.song_limit ? response?.body?.song_limit : 5);
    });
  }, []);

  useEffect(() => {
    fetchNext();
  }, [pageNum]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          setPageNum((no) => no + 1);
        }
      })
  );


  const fetchNext = async () => {
    if (searchValue) {
      const response = await SpotifyService.seach({ q: searchValue, page: pageNum });
      let nextIndex = songsList.length - 1;
      const list = response.map((s, i) => {
        nextIndex = nextIndex + 1;
        return { index: nextIndex, id: s.id, label: s.name, image: s.album?.images[0]?.url, duration: s.duration_ms, artist: s.artists[0].name, spotify_url: s.external_urls?.spotify }
      });
      const appendedList = new Set([...songsList, ...list]);
      setSongsList([...appendedList]);
    }
  };



  const fetchSuggestions = useCallback(debounce(async (input) => {
    if (input && input.length > 5) {
      if (input) {
        const response = await SpotifyService.seach({ q: input, page: pageNum });
        const list = response.map((s, i) => {
          return { index: i, id: s.id, label: s.name, image: s.album?.images[0]?.url, duration: s.duration_ms, artist: s.artists[0].name, spotify_url: s.external_urls?.spotify }
        });
        setSearchValue(input);
        setAutocompleteLoading(false);
        setSongsList(list);
      } else {
        setAutocompleteLoading(false);
      }
    }
  }, 300));

  const handleSearchValue = async (event, selectedValue) => {
    setAutocompleteLoading(true);
    const input = event?.target.value;
    fetchSuggestions(input);
  }

  const songSelector = async (event, selectedValue) => {
    if (selectedValue) {
      const selectedSong = songsList[selectedValue.index]
      setSelectedSong(selectedSong);
    } else {
      setSelectedSong(null);
    }
  }

  const mobileSongSelector = async (selectedValue) => {
    setSongsList([]);
    if (selectedValue) {
      const selectedSong = songsList[selectedValue.index]
      setSelectedSong(selectedSong);
    } else {
      setSelectedSong(null);
    }
  }

  const songSuggesionsSelector = async (selectedValue) => {
    const selectedSong = suggestionsList[selectedValue.index]
    setSelectedSong(selectedSong);
    scrollToViewRef?.current.scrollIntoView();
  }

  const handlePartyName = (e) => {
    setPartyName(e.target.value);
  }

  const saveSongInCookiesWishList = () => {
    const cookiesWishList = cookies.get('cookiesWishList')
    if (cookiesWishList) {
      cookiesWishList.push({ id: selectedSong.id, label: `${selectedSong.artist} - ${selectedSong.label}`, party_name: partyName, spotify_url: selectedSong.spotify_url, artist: selectedSong.artist, image: selectedSong.image, duration: selectedSong.duration, added_at: Date.now() });
      cookies.set('cookiesWishList', cookiesWishList);
    } else {
      const cookiesWishList = [];
      cookiesWishList.push({ id: selectedSong.id, label: `${selectedSong.artist} - ${selectedSong.label}`, party_name: partyName, spotify_url: selectedSong.spotify_url, artist: selectedSong.artist, image: selectedSong.image, duration: selectedSong.duration, added_at: Date.now() });
      cookies.set('cookiesWishList', cookiesWishList);
    }
  }

  const verifyLimit = () => {
    const cookiesWishLisFromCookies = cookies.get('cookiesWishList');
    if (!cookiesWishLisFromCookies) {
      return true;
    }
    const cookiesWishList = cookiesWishLisFromCookies.filter((q) => (!q.isRejected && !q.isApproved));
    return cookiesWishList.length < Number(songLimit);
  }

  const submitForm = async () => {

    setDialogueIsOpen(false);
    try {
      await SpotifyService.addSongToQueue({ song_id: selectedSong.id, track_name: `${selectedSong.artist} - ${selectedSong.label}`, party_name: partyName, spotify_url: selectedSong.spotify_url });
      setIsSubmitting(false);
      enqueueSnackbar('Song wurde vorgeschlagen', { variant: 'success' });
      saveSongInCookiesWishList();
      navigate('/wish-queue');
    } catch (e) {
      setIsSubmitting(false);
    }
  }

  const handleSubmit = async () => {
    if (verifyLimit()) {
      if (selectedSong && partyName) {
        try {
          setIsSubmitting(true);
          const response = await SpotifyService.findLyrics({ song_id: selectedSong.id });
          if (response.message === "Lyrics are not present") {
            setDialogueIsOpen(true);
          } else {
            submitForm();
          }
        } catch (e) {
          setIsSubmitting(false);
        }
      }
    } else {
      enqueueSnackbar('Song limit exceeds', { variant: 'error' });
    }
  }

  const handleCloseDialogue = () => {
    setIsSubmitting(false);
    setDialogueIsOpen(false);
  }

  const emptySongsList = () => {
    outlinedInputRef.current.value = "";
    setSongsList([]);
    setSearchValue(null);
    setSelectedSong(null);
  }

  return <>
    <Dialog
      disableEnforceFocus
      open={dialogueIsOpen}
      onClose={() => setDialogueIsOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Song Lyrics</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Atention! There are no lyrics available. You must know the text by heart. You want to submit this song anyway?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={submitForm}>Yes</Button>
        <Button onClick={handleCloseDialogue} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
    <Home songsList={songsList} handleSearchValue={handleSearchValue} selectedSong={selectedSong} partyName={partyName} handlePartyName={handlePartyName} handleSubmit={handleSubmit} isSubmitting={isSubmitting} autocompleteLoading={autocompleteLoading} songSelector={songSelector} mobileSongSelector={mobileSongSelector} setLastElement={setLastElement} emptySongsList={emptySongsList} outlinedInputRef={outlinedInputRef} suggestionsList={suggestionsList} songSuggesionsSelector={songSuggesionsSelector} scrollToViewRef={scrollToViewRef} />
  </>
}
export default HomeContainer;