import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container, Box } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import HomeContainer from "./Container/Home";
import QueueContainer from "./Container/Queue";
import AdminQueueContainer from "./Container/AdminQueue";
import WishQueueContainer from "./Container/WishQueue";
import AuthorizeContainer from "./Container/Authorize";
import CallbackContainer from "./Container/Callback";
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from "./Utils/SnackbarUtils";
import AdminLimitSongFormContainer from "./Container/AdminLimitSongForm";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
        <Container maxWidth="lg">
          <Box>
            <SnackbarProvider
              maxSnack={2}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <SnackbarUtilsConfigurator />

              <Header theme={darkTheme} />
              <Routes>
                <Route exact path="/" element={<HomeContainer />} />
                <Route exact path="/queue" element={<QueueContainer />} />
                <Route exact path="/admin-queue" element={<AdminQueueContainer />} />
                <Route exact path="/admin-limit-song" element={<AdminLimitSongFormContainer />} />
                <Route exact path="/wish-queue" element={<WishQueueContainer />} />
                <Route exact path="/authorize" element={<AuthorizeContainer />} />
                <Route exact path="/callback" element={<CallbackContainer />} />
              </Routes>
              
            </SnackbarProvider>

            <CssBaseline />
          </Box>
          
        </Container>
        <Footer />
      </ThemeProvider>


    </Router>
  );
};

export default App;