import React from "react";
import { Grid, Button, Box } from '@material-ui/core';
import { Typography, Divider, CircularProgress } from '@mui/material';
import DesktopViewSelectedSong from "../Common/DesktopViewSelectedSong";
import MobileViewSelectedSong from "../Common/MobileViewSelectedSong";
import truncateString from "../Utils/truncateString";

const CurrentlyPlaying = (props) => {
  const { currentlyPlayingSong, currentlyPlaying, isLoading, mobileView } = props;
  return <>
    <Grid item xs={12} className='mt-20'>
      <Grid container justifyContent="space-between">
        <Grid item xxl={7} xl={7} lg={7} md={7} sm={7} xs={12} >
          <Typography variant="h4" gutterBottom>
            {currentlyPlayingSong ? "Currently Playing" : ""}
          </Typography>
        </Grid>
        <Grid item xxl={2} xl={2} lg={2} md={2} sm={2} xs={12}>
          <Button variant="contained" size="large" className="song-vote-button" onClick={currentlyPlaying} fullWidth>{isLoading && <CircularProgress size={14} color="inherit"/>} &nbsp; Auffrischen</Button>
        </Grid>
      </Grid>

    </Grid>
    {currentlyPlayingSong && <Grid item xs={12} className='mt-10 results-container'>
      <Grid container className='p-8'>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom className="mt-20">
            {truncateString(currentlyPlayingSong?.party_name, 18)}
          </Typography>
        </Grid>
      </Grid>
      <Divider className="divider-border-color" />
      <Grid container className="mt-10 p-8">
        <Grid item xxl={1} xl={1} lg={1} md={1} sm={1}>
          <Box
            component="img"
            sx={{
              height: 83,
              width: 95,
            }}
            alt={currentlyPlayingSong?.label}
            src={currentlyPlayingSong?.image}
          />
        </Grid>
        <Grid item xxl={10} xl={10} lg={10} md={10} sm={10}>
          {mobileView ? <MobileViewSelectedSong selectedSong={currentlyPlayingSong} /> : <DesktopViewSelectedSong selectedSong={currentlyPlayingSong} />}

        </Grid>
      </Grid>
    </Grid>}
  </>
}

export default CurrentlyPlaying;