import React from "react";
import { Grid, Box } from '@material-ui/core';
import millisToMinutesAndSeconds from "../Utils/millisToMinutesAndSeconds";
import truncateString from "../Utils/truncateString";

const MobileViewSelectedSong = (props) => {
  const { selectedSong } = props;
  return <Grid container justifyContent='flex-end' className='pl-15'>
    <Grid item>
      <div style={{ fontWeight: 600, margin: 0, lineHeight: 1 }}>
        {truncateString(selectedSong?.label, 18)}
      </div>
      <div style={{ marginTop: 15 }}>
        {selectedSong?.artist}
      </div>
      <div style={{ marginTop: 5 }}>

        {millisToMinutesAndSeconds(selectedSong?.duration)}
      </div>
    </Grid>
  </Grid>
}

export default MobileViewSelectedSong;