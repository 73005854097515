import React from "react";
import { Grid, Box } from '@material-ui/core';
import { Typography } from '@mui/material';
import millisToMinutesAndSeconds from "../Utils/millisToMinutesAndSeconds";

const DesktopViewSelectedSong = (props) => {
  const { selectedSong } = props;
  return <Grid container className='pl-15'>
    <Grid item xs={11}>
      <Typography variant="subtitle1" gutterBottom className="mt-10" style={{ fontWeight: 600, margin: 0, lineHeight: 1 }}>
        {selectedSong?.label}
      </Typography>
      <Typography variant="caption" gutterBottom>
        {selectedSong?.artist}
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography variant="caption" className='pl-110' gutterBottom>
        {millisToMinutesAndSeconds(selectedSong?.duration)}
      </Typography>
    </Grid>
  </Grid>
}

export default DesktopViewSelectedSong;