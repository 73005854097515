import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import { Typography, Divider } from '@mui/material';
import DesktopViewSelectedSong from '../Common/DesktopViewSelectedSong';
import MobileViewSelectedSong from '../Common/MobileViewSelectedSong';
import CurrentlyPlaying from './CurrentlyPlaying';
import truncateString from "../Utils/truncateString";

const AdminQueue = (props) => {
  const { currentlyPlaying, isLoading, currentlyPlayingSong, queue } = props;
  const [state, setState] = useState({ mobileView: false });
  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <Grid container className='mt-100' justifyContent="center">
      <CurrentlyPlaying currentlyPlaying={currentlyPlaying} isLoading={isLoading} currentlyPlayingSong={currentlyPlayingSong} mobileView={mobileView} />
      <Grid item xs={12} className='mt-50'>
        <Grid container justifyContent="space-between">
          <Grid item xs={7} >
            <Typography variant="h4" gutterBottom>
              {currentlyPlayingSong ? "Up Next" : ""}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {queue.map((q, i) => {
        return <Grid item xs={12} className='mt-20 results-container' key={i}>

          <Grid container className='p-8'>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom className="mt-20">
                {mobileView ? truncateString(q?.party_name, 18) : q?.party_name}
              </Typography>
            </Grid>
          </Grid>
          <Divider className="divider-border-color" />

          <Grid container className='mt-10 p-8'>
            <Grid item xxl={1} xl={1} lg={1} md={1} sm={1}>
              <Box
                component="img"
                sx={{
                  height: 83,
                  width: 95,
                }}
                alt={q?.label}
                src={q?.image}
              />
            </Grid>
            <Grid item xxl={10} xl={10} lg={10} md={10} sm={10}>
              {mobileView ? <MobileViewSelectedSong selectedSong={q} /> : <DesktopViewSelectedSong selectedSong={q} />}
            </Grid>
          </Grid>
        </Grid>
      })}
      <Grid item xs={12} style={{ paddingBottom: 100 }}></Grid>
    </Grid>
  );
}

export default AdminQueue;